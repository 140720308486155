.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFFFFF;
    
  }
  
  .login-box {
    position: relative;
    padding-top: 50px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }

  .closebutton {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px;
  }

  .inner-login{
    display: grid;
    align-items: center; 
    place-items:center;
    gap: 20px;
    justify-content: center;

  }

  .logo {
    width: 300px; /* Adjust logo size as needed */
    height: 85px;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    font-size: 24px;
    color: #333;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .login-button {
    width: 95%;
    padding: 12px;
    /* background-color: #333; */
    background: #505769;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 22px;
    cursor: pointer;
    font-family: CustomFont;
  }
  
  .login-button:hover {
    background-color: #555;
  }
  