/* Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFFFFF;
    
  }
  
  .login-box {
    width: 520px; /* Increase the width as needed */
    padding: 70px; /* Increase the padding as needed */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }

x
  .logo {
    width: 300px; /* Adjust logo size as needed */
    height: 85px;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    font-size: 24px;
    color: #333;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .forgot-password {
    margin-bottom: 20px;
    color: #007bff;
    text-decoration: none;
    font-size: 18px;
    font-family: CustomFont;
    font-weight: 300;
    letter-spacing: 0.5px;
  }
  
  .login-button {
    width: 95%;
    padding: 12px;
    /* background-color: #333; */
    background: #505769;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 22px;
    cursor: pointer;
    font-family: CustomFont;
  }
  
  .login-button:hover {
    background-color: #555;
  }
  