.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 210px;
  height: 100%;
  background-color: #f4f4f4;
  transition: left 0.3s ease;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  z-index: 1000;
}

.sidebar.open {
  left: 0;
}

.options {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 28px;
}

.options a {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #333;
  text-decoration: none;
}

.options a:hover {
  background-color: #ddd;
}

.image {
  margin-top: 70px;
  cursor: pointer;
  width: 90%;
}
