.payment {
    width: 61px;
    height: 30px;
    font-size: 18px;
}


.status {
    font-size: 18px;
    width: 105px;
    height: 30px;
}