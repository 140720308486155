@font-face {
    font-family: 'CustomFont';
    src: url('./Fonts/AirbnbCereal_W_Bk.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./Fonts/AirbnbCereal_W_Blk.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./Fonts/AirbnbCereal_W_Lt.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./Fonts/AirbnbCereal_W_Md.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./Fonts/AirbnbCereal_W_XBd.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./Fonts/AirbnbCereal_W_Bd.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
